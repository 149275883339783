<template>
  <div class="repayment">
    <mt-header :title="$t('repayment')">
      <call-phone slot="right" />
    </mt-header>
    <div class="bar">
      <div class="title">{{ $t('referenceNo') }}</div>
      <div class="val">{{ referenceNo || '- - - - - - - - - -' }}</div>
      <mt-button v-if="referenceNo" plain @click="popupVisible = true">{{ $t('repayment') }}</mt-button>
    </div>
    <div class="content">
      <div class="title">{{ $t('repaymentMethod') }}</div>
      <div>
        <div class="box">
          <div class="info">{{ $t('repaymentMethodInfo1') }}</div>
          <div class="item">
            <div class="partners">
              <img src="../assets/7-eleven.png" width="28px">
            </div>
            <span>{{ $t('paymentPartners1') }}</span>
          </div>
          <div class="item">
            <div class="partners">
              <img src="../assets/bayad_center.png" width="32px">
            </div>
            <span>{{ $t('paymentPartners2') }}</span>
          </div>
          <div class="item">
            <div class="partners">
              <img src="../assets/SM.png" width="33px">
            </div>
            <span>{{ $t('paymentPartners3') }}</span>
          </div>
          <div class="item">
            <div class="partners">
              <img src="../assets/LBC_Express.png" width="40px">
            </div>
            <span>{{ $t('paymentPartners4') }}</span>
          </div>
          <div class="item">
            <div class="partners">
              <img src="../assets/Robinsons.png" width="30px">
            </div>
            <span>{{ $t('paymentPartners5') }}</span>
          </div>
          <div class="item">
            <div class="partners">
              <img src="../assets/cebuana_lhuillier.png" width="53px">
            </div>
            <span>{{ $t('paymentPartners6') }}</span>
          </div>
          <div class="steps">
            <div class="title">{{ $t('cebuanaLhuillierStepsTitle1') }}</div>
            <div class="list">
              <div class="val">
                <div class="step">{{ $t('step') }}1</div>
                <div class="tips">{{ $t('cebuanaLhuillierSteps1List1') }}</div>
              </div>
              <div class="val">
                <div class="step">{{ $t('step') }}2</div>
                <div class="tips">{{ $t('cebuanaLhuillierSteps1List2') }}</div>
              </div>
              <div class="val">
                <div class="step">{{ $t('step') }}3</div>
                <div class="tips">{{ $t('cebuanaLhuillierSteps1List3') }}</div>
              </div>
            </div>
          </div>
          <div class="steps">
            <div class="title">{{ $t('cebuanaLhuillierStepsTitle2') }}</div>
            <div class="list">
              <div class="val">
                <div class="step">{{ $t('step') }}1</div>
                <div class="tips">{{ $t('cebuanaLhuillierSteps2List1') }}</div>
              </div>
              <div class="val">
                <div class="step">{{ $t('step') }}2</div>
                <div class="tips">{{ $t('cebuanaLhuillierSteps2List2') }}</div>
              </div>
              <div class="val">
                <div class="step">{{ $t('step') }}3</div>
                <div class="tips">{{ $t('cebuanaLhuillierSteps2List3') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="info">{{ $t('repaymentMethodInfo2') }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="$router.push('/mine/help')">{{
        $t("otherHelp")
      }}</mt-button>
    </div>
    <tab-bar />

    <mt-popup
      v-model="popupVisible"
    >
      <div class="box">
        <div class="title">{{ $t('remainingAmountDue') }} {{ item.repayAmount | formatMoney }}</div>
        <div class="tips">{{ $t('applicationRepayment') }}</div>
        <div class="content">
          <mt-field
            :label="$t('amount')"
            :state="amountState"
            :placeholder="$t('amountPlaceholder')"
            v-model="amount"
          ></mt-field>
        </div>
        <div class="button">
          <mt-button type="primary" size="large" @click="submit">{{
            $t("confirm")
          }}</mt-button>
          <mt-button type="default" size="large" @click="popupVisible = false">{{
            $t("cancel")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>

  </div>
</template>

<script>
import { mapState } from "vuex"
import callPhone from '@/components/callPhone.vue'
import tabBar from '@/components/tabBar.vue'
import { repayCode, contractDetail } from '../utils/api'
export default {
  name: 'Repayment',
  components: {
    callPhone,
    tabBar,
  },
  data() {
    return {
      referenceNo: '',
      popupVisible: false,
      item: {},
      amount: '0',
    }
  },
  computed: {
    ...mapState(["user"]),
    amountState() {
      if (this.amount > this.item.repayAmount || this.amount <= 0) {
        return 'error'
      }
      return 'success'
    }
  },
  watch: {
    popupVisible(val) {
      if (val) {
        this.amount = this.item.repayAmount
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await Promise.all([
        this.getReferenceNo(),
        this.getContractDetail(),
      ])
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getReferenceNo() {
      if (!this.user.applyId) return
      const formData = new FormData()
      formData.append('applyId', this.user.applyId)
      await this.$axios({
        method: 'post',
        url: repayCode,
        data: formData
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.referenceNo = e.body
          }
        })
        .catch(() => {})
    },
    async getContractDetail() {
      if (!this.user.applyId) return
      const formData = new FormData()
      formData.append('applyId', this.user.applyId)
      await this.$axios({
        method: 'post',
        url: contractDetail,
        data: formData
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.item = e.body
          }
        })
        .catch(() => {})
    },
    async submit() {
      if (this.amountState != 'success') {
        return this.$toast(this.$t('amountPlaceholder'));
      }
      this.$router.push({path: '/apply/barCode', query: {amount: this.amount, referenceNo: this.referenceNo}})
    }
  }
}
</script>

<style lang="scss" scoped>
.repayment {
  font-weight: 500;
  
  .bar {
    padding: 15px 10px;
    background: linear-gradient(0deg, #4898F0, #266BB7);
    color: #FFFFFF;
    text-align: center;
    .title {
      font-size: 18px;
    }
    .val {
      font-size: 25px;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .mint-button {
      color: white;
      border: 1px solid white;
      height: 34px;
    }
  }
  > .content {
    > .title {
      font-size: 18px;
      color: #383838;
      text-align: center;
      margin-top: 15px;
      font-weight: 500;
      margin-bottom: 30px;
    }
    .box {
      margin: 0px 10px;
      .info {
        font-size: 14px;
        color: #4E4E4E;
        line-height: 18px;
        display: flex;
        font-weight: 500;
        padding-bottom: 22px;
        &::before {
          content: '';
          width: 24px;
          height: 24px;
          border-radius: 24px;
          background-color: #E6E6E6;
          color: #474747;
          font-size: 14px;
          font-weight: bold;
          display: table;
          margin-right: 10px;
          text-align: center;
          line-height: 24px;
        }
      }
      &:nth-child(1) {
        .info::before {
          content: '1';
        }
      }
      &:nth-child(2) {
        .info::before {
          content: '2';
        }
      }
      .item {
        margin-left: 30px;
        color: #4E4E4E;
        font-size: 12px;
        margin-bottom: 12px;
        display: flex;
        .partners {
          width: 53px;
          text-align: center;
          margin-right: 13px;
        }
        span {
          flex: 1;
        }
      }
      .steps {
        margin: 0px 10px 0px 37px;
        line-height: 24px;
        .title {
          font-size: 12px;
          color: #F15234;
          font-weight: bold;
        }
        .list {
          margin-top: 15px;
          .val {
            display: flex;
            font-size: 12px;
            color: #474747;
            padding-bottom: 30px;
            .step {
              margin-right: 15px;
            }
            .tips {
              flex: 1;
            }
          }
        }
      }
    }
  }
  .bottom {
    padding: 20px 20px 20px 20px;
    .mint-button {
      border-radius: 6px;
    }
  }
  /deep/ .mint-popup {
    .box {
      min-width: 300px;
      padding: 10px;
      .title {
        font-weight: 600;
        padding-bottom: 10px;
      }
      .tips {
        font-size: 12px;
        padding: 5px;
      }
      .button {
        padding-top: 20px;
        display: flex;
        button {
          margin: 0px 10px;
        }
      }
    }
  }
}
</style>
<style>
.mint-toast {
  z-index: 2002;
}
</style>